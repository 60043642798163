import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { SubmissionError } from "redux-form";

import set from "lodash/set";

import { RegistrationWizard } from "../../containers/form";
import * as formSections from "../../containers/form/formSections";
import { messages, constants, options } from "../../constants";
import * as selectors from "../../selectors";
import {
  register,
  registerEbike,
  validateBusinessEmailOtp,
  updateRegister,
  registerBusinessAccount
} from "../../api/propel";
import { filterAndFormatAddress, stripSymbols } from "../../util/regHelper";
import { flow } from "../../actions";
import * as bannerActions from "../../actions/banner";
import * as settingReduxActions from "../../actions/settings";
import { ErrorBoundary } from "../../components";
import { formatDate } from "../../util";

import { findIndex, trim } from "lodash";

class Registration extends PureComponent {
  state = { loading: false };

  nextPage = () => {
    const { handleSelectIndex, progress, isEbikeOnlyMode } = this.props;
    const renderItem = options.progress(isEbikeOnlyMode)[progress.selectedIndex];
    const increaseSubIndex =
      renderItem.hasSubOptions && progress.selectedSubIndex < renderItem.subOptions.length - 1;

    handleSelectIndex({
      selectedIndex: !increaseSubIndex ? progress.selectedIndex + 1 : progress.selectedIndex,
      selectedSubIndex: increaseSubIndex ? progress.selectedSubIndex + 1 : 0
    });
    this.props.banner.resetAlerts();
  };

  previousPage = () => {
    const { handleSelectIndex, progress, isEbikeOnlyMode } = this.props;

    const prevRenderItem = options.progress(isEbikeOnlyMode)[progress.selectedIndex - 1];
    const decreaseSubIndex = progress.selectedSubIndex > 0;
    const subIndex =
      !decreaseSubIndex && prevRenderItem.hasSubOptions
        ? prevRenderItem.subOptions.length - 1
        : progress.selectedSubIndex;

    handleSelectIndex({
      selectedIndex: !decreaseSubIndex ? progress.selectedIndex - 1 : progress.selectedIndex,
      selectedSubIndex: decreaseSubIndex ? progress.selectedSubIndex - 1 : subIndex
    });
    this.props.banner.resetAlerts();
  };

  _mapErrorCodeMessage = (code, fieldId) => {
    const { intl } = this.props;
    let spec = fieldId;
    switch (code) {
      case 4000: // required
        return intl.formatMessage(messages.error.required, { spec });
      case 4001: // invalid format
        return intl.formatMessage(messages.error.invalidFormat, { spec });
      case 4002: {
        // invalid value
        return intl.formatMessage(messages.error.invalidValue, { spec });
      }
      case 4003: // value too large
        return intl.formatMessage(messages.error.invalidValue, { spec });
      case 4004: // value too small
        return intl.formatMessage(messages.error.invalidValue, { spec });
      case 4005: {
        // duplicate
        if (fieldId === messages.field.EmailAddress.defaultMessage) {
          return intl.formatMessage(messages.asyncValidation.emailAddressDuplicate);
        }
        return intl.formatMessage(messages.error.duplicated, { spec });
      }
      default:
        return null;
    }
  };

  _mapFieldId = (field_Id) => {
    switch (trim(field_Id)) {
      case "preferred_first_name":
        return {
          storeField: "personalInfo.preferredName",
          displayField: messages.personal.preferredName.defaultMessage
        };
      case "phone_number":
        return {
          storeField: "personalInfo.phoneNumber",
          displayField: messages.personal.tel.defaultMessage
        };
      case "email":
        return {
          storeField: "personalInfo.emailAddress",
          displayField: messages.field.EmailAddress.defaultMessage
        };
      case "password":
        return {
          storeField: "personalInfo.password",
          displayField: messages.field.password.defaultMessage
        };
      case "school_name":
        return {
          storeField: "personalInfo.schoolName",
          displayField: messages.field.nameOfSchool.defaultMessage
        };
      case "pronouns":
        return {
          storeField: "personalInfo.pronoun",
          displayField: messages.personal.pronoun.defaultMessage
        };
      case "t_and_c_consent":
        return {
          storeField: "personalInfo.acceptTermsAndConditions",
          displayField: messages.link.memberAgreement.defaultMessage
        };
      case "marketing_consent":
        return { storeField: "personalInfo.marketingOptin", displayField: "Marketing Opt-in" };
      case "first_name":
        return {
          storeField: "driverLicence.firstName",
          displayField: messages.personal.firstName.defaultMessage
        };
      case "last_name":
        return {
          storeField: "driverLicence.lastName",
          displayField: messages.personal.lastName.defaultMessage
        };
      case "middle_name":
        return {
          storeField: "driverLicence.middleName",
          displayField: messages.personal.middleName.defaultMessage
        };
      case "birth_date":
        return {
          storeField: "driverLicence.birthDate",
          displayField: messages.personal.dob.defaultMessage
        };
      case "profile_city":
        return {
          storeField: "driverLicence.primary.city",
          displayField: messages.address.city.defaultMessage
        };
      case "profile_country":
        return {
          storeField: "driverLicence.primary.country",
          displayField: messages.address.country.defaultMessage
        };
      case "profile_province":
        return {
          storeField: "driverLicence.primary.province",
          displayField: messages.address.province.defaultMessage
        };
      case "profile_address":
        return {
          storeField: "driverLicence.primary.line1",
          displayField: messages.address.addressLine.defaultMessage
        };
      case "profile_postal_code":
        return {
          storeField: "driverLicence.primary.postalCode",
          displayField: messages.address.postalZipCode.defaultMessage
        };
      case "profile_is_same_as_billing_addr":
        return { storeField: "driverLicence.isMailingAddress", displayField: "Is Mailing Address" };
      case "billing_city":
        return {
          storeField: "driverLicence.secondary.city",
          displayField: messages.address.city.defaultMessage
        };
      case "billing_country":
        return {
          storeField: "driverLicence.secondary.country",
          displayField: messages.address.country.defaultMessage
        };
      case "billing_province":
        return {
          storeField: "driverLicence.secondary.province",
          displayField: messages.address.province.defaultMessage
        };
      case "billing_address":
        return { storeField: "driverLicence.secondary.line1", displayField: "Mailing Address" };
      case "billing_postal_code":
        return {
          storeField: "driverLicence.secondary.postalCode",
          displayField: messages.address.postalZipCode.defaultMessage
        };
      case "dl_expiry":
        return {
          storeField: "driverLicence.expiryDate",
          displayField: messages.field.driversLicenseExpiry.defaultMessage
        };
      case "membership_number":
        return {
          storeField: "promosAndSavings.memberNumber",
          displayField: messages.member.number.defaultMessage
        };
      case "dl_number":
        return {
          storeField: "driverLicence.number",
          displayField: messages.field.driversLicenseNumber.defaultMessage
        };
      case "promo_code":
        return {
          storeField: "promosAndSavings.promo",
          displayField: messages.field.promoCode.defaultMessage
        };
      // case "cc_number": return {storeField:"paymentInfo.ccNumber", displayField: messages.creditCard.ccNumber.defaultMessage};
      // case "expiry_date": return {storeField:"paymentInfo.ccExpiryDate", displayField: messages.creditCard.expiryDate.defaultMessage};
      // case "cvc": return{storeField:"paymentInfo.cvv", displayField: messages.creditCard.cvv.defaultMessage};
      // case "cardholder_name": return {storeField:"paymentInfo.cardHolderName", displayField: messages.creditCard.cardHolderName.defaultMessage};
      case "evo_card":
        return {
          storeField: "paymentInfo.evoCard",
          displayField: messages.evoCard.evoCard.defaultMessage
        };
      case "dl_front":
        return {
          storeField: "driverLicence.imageFront",
          displayField: messages.field.driverLicenseFront.defaultMessage
        };
      case "dl_back":
        return {
          storeField: "driverLicence.imageBack",
          displayField: messages.field.driverLicenseBackImage.defaultMessage
        };
      case "selfie":
        return {
          storeField: "identityVerificationSelfie.imageSelfie",
          displayField: messages.field.selfie.defaultMessage
        };
      case "gender":
        return null;
      case "dl_issuing_country":
        return null;
      default:
        return null;
    }
  };

  _formatSubmissionErrors = (errors) => {
    return errors.reduce((result, error) => {
      const errorCode = error.error_codes[0];
      const field = this._mapFieldId(error.field_id);
      const errorMsg = this._mapErrorCodeMessage(errorCode, field.displayField);
      set(result, field.storeField, errorMsg);

      return result;
    }, {});
  };

  _handleSubmitEvolve = async (values) => {
    this.setState({ ...this.state, loading: true });

    // promoCode is stored after clicking apply button
    // it has status and message
    const { settingsActions, dropdownOptions, registration_id, otp, isEvoMember, accessCode } =
      this.props;

    //await getAydenData(this.props);
    // 5. The submit operation can be carried on
    return new Promise(async (resolve, reject) => {
      const { personalInfo } = values;

      const {
        firstName,
        lastName,
        middleName,
        preferredName,
        emailAddress,
        password,
        phoneNumber,
        acceptTermsAndConditions,
        marketingOptin,
        pronoun,
        pronounCustom,
        birthDate,
        address
      } = personalInfo;

      let userHomeAddress;
      let billingAddress;

      userHomeAddress = filterAndFormatAddress(address, dropdownOptions.countries, false);
      billingAddress = filterAndFormatAddress(address, dropdownOptions.countries, true);

      let payload = {
        email: emailAddress.toLowerCase(),
        password,
        locale: "EN_CA",

        preferred_first_name: preferredName || firstName,
        first_name: firstName,
        middle_name: middleName || "",
        last_name: lastName,
        birth_date: formatDate(birthDate),
        phone_number: stripSymbols(phoneNumber),
        pronouns: pronoun === "Other" ? pronounCustom : pronoun,
        school_name: "",
        t_and_c_consent: acceptTermsAndConditions,
        marketing_consent: marketingOptin,

        membership_number: "",

        profile_is_same_as_billing_addr: false,
        promo_code: "",

        profile_city: userHomeAddress.city,
        profile_country: userHomeAddress.country,
        profile_province: userHomeAddress.province,
        profile_address: userHomeAddress.addressLine,
        profile_postal_code: userHomeAddress.postalCode,

        billing_city: billingAddress.city,
        billing_country: billingAddress.country,
        billing_province: billingAddress.province,
        billing_address: billingAddress.addressLine,
        billing_postal_code: billingAddress.postalCode
      };

      try {
        let registerIsSuccessful = false;
        settingsActions.toggleSpinnerOn();
        const response_register = await registerEbike(payload);

        const data = await response_register.json();
        if (response_register.status === 201) {
          const { setRegistrationResp } = this.props;
          // Success
          setRegistrationResp({ registrationResp: { ...data, emailAddress, phoneNumber } });
          resolve();
          window.dataLayer.push({ event: "registration_success" });
          registerIsSuccessful = true;
          this.props.banner.resetAlerts();
        } else if (Array.isArray(data.errors) && data.errors.length) {
          // Errors returned from back end
          window.dataLayer.push({ event: "registration_error" });
          const submissionErrors = this._formatSubmissionErrors(data.errors);
          throw new SubmissionError(submissionErrors);
        } else if (data.error) {
          window.dataLayer.push({ event: "registration_error" });
          throw new Error(data.error);
        } else {
          window.dataLayer.push({ event: "registration_error" });
          throw new Error("unknown error");
        }

        //if register returned successfully, and we have the Business isEvolveMember set to 'No'
        //call the validateBusinessEmailOtp(registration_id, otp) to complete the business registration
        if (isEvoMember === false && registerIsSuccessful === true && registration_id) {
          const response_b2b_register = await validateBusinessEmailOtp(
            registration_id,
            otp,
            true,
            data.customer_id
          );

          if (response_b2b_register.status === 201 || response_b2b_register.status === 200) {
            resolve();
            settingsActions.toggleSpinnerOff();

            this.nextPage();
          }
        } else if (isEvoMember === false && accessCode) {
          console.log("Option2 " + accessCode);
          let payload = {
            is_member: isEvoMember,
            business_email: null,
            access_code: accessCode,
            personal_email: emailAddress, //personalEmailAddress
            customer_id: data.customer_id
          };
          console.log("payload", payload);

          settingsActions.toggleSpinnerOn();
          const response = await registerBusinessAccount(payload);
          // let it pass through regardless if it is successful,
          // since we do not want to stop the registration at this point
          // we should validate in the beginning....
          if (response.status > 201) {
            console.error("Unable to add business profile");
          }
          resolve();
          settingsActions.toggleSpinnerOff();
          this.nextPage();
        } else if (registerIsSuccessful) {
          resolve();
          settingsActions.toggleSpinnerOff();
          this.nextPage();
        } else {
          settingsActions.toggleSpinnerOff();
        }
      } catch (e) {
        this.setState({ ...this.state, loading: false });
        settingsActions.toggleSpinnerOff();
        reject(e);
      }

      //
      // if register did not return successfully, error should be handled above already.
    });
  };

  _handleSubmit = async (values) => {
    this.setState({ ...this.state, loading: true });

    // promoCode is stored after clicking apply button
    // it has status and message
    const {
      setPaymentSummary,
      settingsActions,
      dropdownOptions,
      registration_id,
      otp,
      isEvoMember,
      summaryParam,
      intl,
      handlePromoCodeUpdate,
      accessCode
    } = this.props;

    // promoCode is not applied nor has a status
    if (values.promosAndSavings && values.promosAndSavings.promo) {
      const newPromoCode = await handlePromoCodeUpdate(values.promosAndSavings.promo, intl);

      if (newPromoCode.status !== constants.PROMO_CODE_STATUS.primary) {
        throw new SubmissionError({ promosAndSavings: { promo: newPromoCode.message } });
      } else {
        setPaymentSummary({ ...summaryParam, promoCode: newPromoCode.code });
      }
    } else {
      setPaymentSummary({ ...summaryParam });
    }

    //await getAydenData(this.props);
    // 5. The submit operation can be carried on
    return new Promise(async (resolve, reject) => {
      const {
        personalInfo,
        driverLicence,
        promosAndSavings,
        identityVerificationSelfie,
        paymentInfo
      } = values;

      const {
        preferredName,
        emailAddress,
        password,
        phoneNumber,
        acceptTermsAndConditions,
        marketingOptin,
        pronoun,
        pronounCustom,
        isEbikeOnly,
        // birthDate,
        address
      } = personalInfo;

      const {
        firstName,
        lastName,
        middleName,
        number,
        expiryDate,
        isMailingAddress,
        imageFront,
        imageBack,
        birthDate
      } = driverLicence;

      const { promo, iscaaMember, memberNumber } = promosAndSavings;

      const {
        // ccNumber,
        // ccExpiryDate,
        // cvv,
        // cardHolderName,
        evoCard
      } = paymentInfo;

      let userHomeAddress;
      let billingAddress;

      // Assign the Dl_IssuingCountry to 2 letter abbreviation
      const index = findIndex(
        dropdownOptions.countries,
        (x) => x.value === driverLicence.primary.country
      );
      const dl_IssuingCountry = dropdownOptions.countries[index].shortAbbr;

      // evolve flow does not have the isMailingAddress flag, so the flag is always undefined
      // evolve flow only has the primary address, so that address will be used for billing as well
      // evo flow has the isMailing address flag.  True means only one address was provided.
      // false means a secondary address was provided
      if (driverLicence && driverLicence.primary) {
        userHomeAddress = filterAndFormatAddress(
          isMailingAddress ? driverLicence.primary : driverLicence.secondary,
          dropdownOptions.countries,
          false
        );
        billingAddress = filterAndFormatAddress(
          driverLicence.primary,
          dropdownOptions.countries,
          true
        );
      }

      if (isEbikeOnly) {
        userHomeAddress = filterAndFormatAddress(address, dropdownOptions.countries, false);
        billingAddress = filterAndFormatAddress(address, dropdownOptions.countries, true);
      }

      let payload;

      let backImage, frontImage, selfieImage;

      if (isEbikeOnly === false) {
        const { imageSelfie } = identityVerificationSelfie;

        if (imageFront) {
          frontImage = driverLicence.imageFront;
          frontImage = await fetch(frontImage).then((r) => r.blob());
          frontImage = new File([frontImage], "test", { type: frontImage.type });
        }

        if (imageBack) {
          backImage = driverLicence.imageBack;
          backImage = await fetch(backImage).then((r) => r.blob());
          backImage = new File([backImage], "test", { type: backImage.type });
        }
        if (imageSelfie) {
          selfieImage = identityVerificationSelfie.imageSelfie;
          selfieImage = await fetch(selfieImage).then((r) => r.blob());
          selfieImage = new File([selfieImage], "test", { type: selfieImage.type });
        }
      }

      if (isEbikeOnly === false) {
        payload = {
          email: emailAddress.toLowerCase(),
          password,
          locale: "EN_CA",

          preferred_first_name: preferredName || firstName,
          first_name: firstName,
          middle_name: middleName || "",
          last_name: lastName,
          birth_date: formatDate(birthDate),
          phone_number: stripSymbols(phoneNumber),
          pronouns: pronoun === "Other" ? pronounCustom : pronoun,
          school_name: "",
          t_and_c_consent: acceptTermsAndConditions,
          marketing_consent: marketingOptin,

          membership_number: iscaaMember === "Yes" ? memberNumber : "",

          profile_is_same_as_billing_addr: !!isMailingAddress,
          promo_code: promo || "",

          dl_issuing_country: dl_IssuingCountry,
          province: driverLicence.province ? driverLicence.province : "",
          dl_number: number,
          dl_expiry: formatDate(expiryDate),

          profile_city: userHomeAddress.city,
          profile_country: userHomeAddress.country,
          profile_province: userHomeAddress.province,
          profile_address: userHomeAddress.addressLine,
          profile_postal_code: userHomeAddress.postalCode,

          billing_city: billingAddress.city,
          billing_country: billingAddress.country,
          billing_province: billingAddress.province,
          billing_address: billingAddress.addressLine,
          billing_postal_code: billingAddress.postalCode,

          // cc_number: ccNumber.replace(/\s/g, ''),
          // cvc: cvv,
          // cardholder_name: cardHolderName,
          // expiry_date: ccExpiryDate,

          evo_card: evoCard
        };
      } else {
        payload = {
          email: emailAddress.toLowerCase(),
          password,
          locale: "EN_CA",

          preferred_first_name: preferredName || firstName,
          first_name: firstName,
          middle_name: middleName || "",
          last_name: lastName,
          birth_date: formatDate(birthDate),
          phone_number: stripSymbols(phoneNumber),
          pronouns: pronoun === "Other" ? pronounCustom : pronoun,
          school_name: "",
          t_and_c_consent: acceptTermsAndConditions,
          marketing_consent: marketingOptin,

          membership_number: iscaaMember === "Yes" ? memberNumber : "",

          profile_is_same_as_billing_addr: !!isMailingAddress,
          promo_code: promo || "",

          profile_city: userHomeAddress.city,
          profile_country: userHomeAddress.country,
          profile_province: userHomeAddress.province,
          profile_address: userHomeAddress.addressLine,
          profile_postal_code: userHomeAddress.postalCode,

          billing_city: billingAddress.city,
          billing_country: billingAddress.country,
          billing_province: billingAddress.province,
          billing_address: billingAddress.addressLine,
          billing_postal_code: billingAddress.postalCode

          // cc_number: ccNumber.replace(/\s/g, ''),
          // cvc: cvv,
          // cardholder_name: cardHolderName,
          // expiry_date: ccExpiryDate
        };
      }

      try {
        let registerIsSuccessful = false;
        settingsActions.toggleSpinnerOn();
        const response_register = await register(
          payload,
          frontImage,
          backImage,
          selfieImage,
          isEbikeOnly
        );

        const data = await response_register.json();
        if (response_register.status === 201) {
          const { setRegistrationResp } = this.props;
          // Success
          setRegistrationResp({ registrationResp: { ...data, emailAddress, phoneNumber } });
          resolve();
          window.dataLayer.push({ event: "registration_success" });
          registerIsSuccessful = true;
          this.props.banner.resetAlerts();
        } else if (Array.isArray(data.errors) && data.errors.length) {
          // Errors returned from back end
          window.dataLayer.push({ event: "registration_error" });
          const submissionErrors = this._formatSubmissionErrors(data.errors);
          throw new SubmissionError(submissionErrors);
        } else if (data.error) {
          window.dataLayer.push({ event: "registration_error" });
          throw new Error(data.error);
        } else {
          window.dataLayer.push({ event: "registration_error" });
          throw new Error("unknown error");
        }

        //if register returned successfully, and we have the Business isEvolveMember set to 'No'
        //call the validateBusinessEmailOtp(registration_id, otp) to complete the business registration
        if (isEvoMember === false && registerIsSuccessful === true && registration_id) {
          const response_b2b_register = await validateBusinessEmailOtp(
            registration_id,
            otp,
            true,
            data.customer_id
          );
          if (response_b2b_register.status === 201 || response_b2b_register.status === 200) {
            resolve();
            settingsActions.toggleSpinnerOff();

            this.nextPage();
          }
        } else if (isEvoMember === false && accessCode) {
          let payload = {
            is_member: isEvoMember,
            business_email: null,
            access_code: accessCode,
            personal_email: emailAddress,
            customer_id: data.customer_id
          };
          // console.log("payload", payload);

          settingsActions.toggleSpinnerOn();
          const response = await registerBusinessAccount(payload);
          // let it pass through regardless if it is successful,
          // since we do not want to stop the registration at this point
          // we should validate in the beginning....
          if (response.status > 201) {
            console.error("Unable to add business profile");
          }
          resolve();
          settingsActions.toggleSpinnerOff();
          this.nextPage();
        } else if (registerIsSuccessful) {
          resolve();
          settingsActions.toggleSpinnerOff();
          this.nextPage();
        } else {
          settingsActions.toggleSpinnerOff();
        }
      } catch (e) {
        this.setState({ ...this.state, loading: false });
        settingsActions.toggleSpinnerOff();
        reject(e);
      }

      //
      // if register did not return successfully, error should be handled above already.
    });
  };

  _handleUpdateEvolve = async (values) => {
    this.setState({ ...this.state, loading: true });

    // promoCode is stored after clicking apply button
    // it has status and message
    return new Promise(async (resolve, reject) => {
      const { settingsActions, state } = this.props;
      console.log("state", state.registration);

      const { customer_id, entity_id } = state.registration.registrationResp;

      const { promosAndSavings } = values;

      const { promo, iscaaMember, memberNumber } = promosAndSavings;

      const payload = {
        membership_number: iscaaMember === "Yes" ? memberNumber : "",
        promo_code: promo || "",
        customer_id,
        entity_id
      };

      try {
        settingsActions.toggleSpinnerOn();
        const response_register = await updateRegister(payload);

        const data = await response_register.json();
        if (response_register.status === 201) {
          resolve();
          window.dataLayer.push({ event: "registration_update_success" });
          this.props.banner.resetAlerts();
        } else if (Array.isArray(data.errors) && data.errors.length) {
          // Errors returned from back end
          window.dataLayer.push({ event: "registration_update_error" });
          const submissionErrors = this._formatSubmissionErrors(data.errors);
          throw new SubmissionError(submissionErrors);
        } else if (data.error) {
          window.dataLayer.push({ event: "registration_update_error" });
          throw new Error(data.error);
        } else {
          window.dataLayer.push({ event: "registration_update_error" });
          throw new Error("unknown error");
        }
        settingsActions.toggleSpinnerOff();
        this.nextPage();
      } catch (e) {
        this.setState({ ...this.state, loading: false });
        settingsActions.toggleSpinnerOff();
        reject(e);
      }
    });
  };

  nextAction = (values) => {
    const { progress, isEbikeOnlyMode, state } = this.props;
    const stepName = options.progress(isEbikeOnlyMode)[progress.selectedIndex].name;
    if (isEbikeOnlyMode && progress.selectedIndex === 0) {
      return this._handleSubmitEvolve(values);
    } else if (isEbikeOnlyMode && progress.selectedIndex === 1) {
      return this._handleUpdateEvolve(values);
    }

    if (progress.selectedIndex === 3 && stepName === "PromosAndSavings") {
      return this._handleSubmit(values);
    }

    if (stepName === "PaymentInfo") {
      if (state.adyen.adyenResp && state.adyen.adyenResp.resultCode === "Authorised") {
        return this.nextPage();
      } else {
        return;
      }
    }

    return this.nextPage();
  };

  render() {
    const { progress, intl, isEbikeOnlyMode } = this.props;
    const { loading } = this.state;

    const PersonalInfo = {
      title: intl.formatMessage(messages.title.ContactInfoTitle),
      section: "personalInfo",
      component: formSections["PersonalInfo"]
    };

    const IdentityVerificationSelfie = {
      title: intl.formatMessage(messages.title.IdentityVerificationInfoTitle),
      section: "identityVerificationSelfie",
      component: formSections.IdentityVerificationSelfie
    };
    const PromosAndSavings = {
      title: intl.formatMessage(messages.title.PromosAndSavingsInfoTitle),
      section: "promosAndSavings",
      component: formSections.PromosAndSavings
    };

    const DriversLicenseInfo = {
      title: isEbikeOnlyMode
        ? intl.formatMessage(messages.title.EbikePersonalInfoConfirmDetailsTitle)
        : intl.formatMessage(messages.title.DriversLicenseInfoConfirmDetailsTitle),
      section: "driverLicence",
      component: formSections.DriversLicenseInfo
    };
    const AddDriversLicenseFront = {
      title: intl.formatMessage(messages.title.DriversLicenseInfoTitle),
      section: "driverLicence",
      component: formSections.AddDriversLicenseFront
    };

    const AddDriversLicenseBack = {
      title: intl.formatMessage(messages.title.DriversLicenseInfoTitle),
      section: "driverLicence",
      component: formSections.AddDriversLicenseBack
    };
    const PaymentInfo = {
      title: isEbikeOnlyMode
        ? intl.formatMessage(messages.title.CreditCardInfoProgressTitle)
        : intl.formatMessage(messages.title.PaymentInfo),
      section: "paymentInfo",
      component: formSections.PaymentInfo
    };
    const RequestDrivingRecord = {
      title: intl.formatMessage(messages.title.RequestDrivingRecord),
      section: "requestDrivingRecord",
      component: formSections.RequestDrivingRecord
    };
    const EbikeOnlyConfirmation = {
      title: intl.formatMessage(messages.title.EbikeConfrimation),
      section: "ebikeCofirmation",
      component: formSections.EbikeConfirmation
    };

    const renderOptions = {
      PersonalInfo,
      IdentityVerificationSelfie,
      PromosAndSavings,
      AddDriversLicenseFront,
      AddDriversLicenseBack,
      DriversLicenseInfo,
      PaymentInfo,
      RequestDrivingRecord,
      EbikeOnlyConfirmation
    };

    const renderItem = options.progress(isEbikeOnlyMode)[progress.selectedIndex];

    let sections = [];
    if (!renderItem) return null;
    if (renderItem.hasSubOptions)
      sections.push(renderOptions[renderItem.subOptions[progress.selectedSubIndex]]);
    else sections.push(renderOptions[renderItem.name]);

    return (
      <ErrorBoundary name="registration wizard">
        <RegistrationWizard
          submitting={loading}
          onSubmit={this.nextAction} //progress.selectedIndex === 3 ? this._handleSubmit : this.nextPage}
          button={intl.formatMessage(messages.button.continue)}
          sections={sections}
          previousPage={renderItem.allowBack ? this.previousPage : undefined}
          active={renderItem.showContinueButton}
        />
      </ErrorBoundary>
    );
  }
}

Registration.propTypes = {
  intl: PropTypes.object.isRequired,
  progress: PropTypes.object.isRequired,
  handleSelectIndex: PropTypes.func.isRequired,
  handlePromoCodeUpdate: PropTypes.func.isRequired,
  setPaymentSummary: PropTypes.func.isRequired,
  setRegistrationResp: PropTypes.func.isRequired,
  // registerCustomer: PropTypes.func.isRequired,
  promoParam: PropTypes.object,
  summaryParam: PropTypes.object,
  isEbikeOnlyMode: PropTypes.bool,
  promoCode: PropTypes.object,
  form: PropTypes.object,
  banner: PropTypes.objectOf(PropTypes.func),
  settingsActions: PropTypes.objectOf(PropTypes.func),
  dropdownOptions: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.any }))
  ),
  registration_id: PropTypes.any,
  otp: PropTypes.string,
  isEvoMember: PropTypes.bool,
  accessCode: PropTypes.string
};

//const selector = formValueSelector(constants.FORM.registrationForm);
const mapStateToProps = (state) => ({
  state: state,
  progress: selectors.getSelectedIndex(state),
  form: state.form.registration,
  adyen: state.adyen,
  //  moneris: state.moneris,
  promoCode: selectors.getPromoCodeStatus(state),
  // params used to get payment summary
  summaryParam: {
    // signupRegion: selector(state, "homeAddress.signupRegion"),
    isCAAMember: selectors.getiscaaMember(state),
    validateMembership: selectors.getValidateMembership(state),
    promoCode: selectors.getPromoCodeStatus(state).code
  },
  // params used to validate promo code
  promoParam: {
    isSignup: true, // this is signup (registration) flow
    promoCode: selectors.getPromoCodeStatus(state)
  },
  isEbikeOnlyMode: selectors.getIsEbikeOnly(state),
  dropdownOptions: state.settings.dropdownOptions,
  registration_id: selectors.getRegistrationId(state),
  otp: selectors.getOTP(state),
  isEvoMember: selectors.getIsEvoMember(state),
  accessCode: selectors.getAccessCode(state),
  personalEmailAddress: selectors.getPersonalEmail(state)
});

const mapDispatchToProps = (dispatch) => ({
  handleSelectIndex: bindActionCreators(flow.handleSelectIndex, dispatch),
  handlePromoCodeUpdate: bindActionCreators(flow.handlePromoCodeUpdate, dispatch),
  setPaymentSummary: bindActionCreators(flow.setPaymentSummary, dispatch),
  setRegistrationResp: bindActionCreators(flow.setRegistrationResp, dispatch),
  // registerCustomer: bindActionCreators(flow.registerCustomer, dispatch),
  banner: bindActionCreators(bannerActions, dispatch),
  settingsActions: bindActionCreators(settingReduxActions, dispatch)
});

export const IntlRegistration = injectIntl(Registration);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Registration));
