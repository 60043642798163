// Reducer Constants
export const PAGE_THEME = "PAGE_THEME";
export const PAGE_SUB_NAV = "PAGE_SUB_NAV";

export const FLOW_SELECTED_INDEX = "FLOW_SELECTED_INDEX";
export const FLOW_VALID_INDEX = "FLOW_VALID_INDEX";
export const FLOW_EDIT_NAME = "FLOW_EDIT_NAME";
export const FLOW_PAYMENT_SUMMARY = "FLOW_PAYMENT_SUMMARY";
export const FLOW_PROMO_CODE = "FLOW_PROMO_CODE";
export const FLOW_EVOLVE_ONLY = "FLOW_EVOLVE_ONLY";
export const FLOW_DRIVERS_LICENSE_FRONT = "FLOW_DRIVERS_LICENSE_FRONT";
export const FLOW_DRIVERS_LICENSE_BACK = "FLOW_DRIVERS_LICENSE_BACK";
export const FLOW_SELFIE = "FLOW_SELFIE";
export const FLOW_REGISTER = "FLOW_REGISTER";
export const FLOW_VALIDATE_MEMBERSHIP = "FLOW_VALIDATE_MEMBERSHIP";

// Adyen constants for not breaking
export const SET_ADYEN_RESP = "SET_ADYEN_RESP";
export const CLEAR_ADYEN_RESP = "CLEAR_ADYEN_RESP";

// Set response after sending request to our backend
export const SET_REGISTRATION_RESP = "SET_REGISTRATION_RESP";
export const CLEAR_REGISTRATION_RESP = "CLEAR_REGISTRATION_RESP";

// Private Registration Const
// {
export const FLOW_REGISTRATION_ID = "FLOW_REGISTRATION_ID";

// }
export const SET_MONERIS_RESP = "SET_MONERIS_RESP";
export const CLEAR_MONERIS_RESP = "CLEAR_MONERIS_RESP";

export const USER_INFO = "USER_INFO";
export const USER_UPDATE = "USER_UPDATE";
export const USER_DELETE = "USER_DELETE";
export const USER_LOGIN_INFO_GET = "USER_LOGIN_INFO_GET";
export const USER_LOGIN_INFO_PATCH = "USER_LOGIN_INFO_PATCH";
export const USER_PERSONAL_INFO_GET = "USER_PERSONAL_INFO_GET";
export const USER_PERSONAL_INFO_PATCH = "USER_PERSONAL_INFO_PATCH";
export const USER_DRIVER_LICENCE_GET = "USER_DRIVER_LICENCE_GET";
export const USER_DRIVER_LICENCE_UPDATE = "USER_DRIVER_LICENCE_UPDATE";
export const USER_DRIVER_LICENCE_PATCH = "USER_DRIVER_LICENCE_PATCH";
export const USER_PAYMENT_INFO_GET = "USER_PAYMENT_INFO_GET";
export const USER_PAYMENT_INFO_UPDATE = "USER_PAYMENT_INFO_UPDATE";
export const USER_PAYMENT_INFO_PATCH = "USER_PAYMENT_INFO_PATCH";
export const USER_MEMBERSHIP_GET = "USER_MEMBERSHIP_GET";
export const USER_MEMBERSHIP_UPDATE = "USER_MEMBERSHIP_UPDATE";
export const USER_MEMBERSHIP_PATCH = "USER_MEMBERSHIP_PATCH";
export const USER_MEMBERSHIP_DELETE = "USER_MEMBERSHIP_DELETE";

export const BILLING_BALANCES_INFO = "BILLING_BALANCES_INFO";
export const BILLING_PAYMENTS = "BILLING_PAYMENTS";
export const RESET_PAYMENTS = "RESET_PAYMENTS";
export const FREE_MINUTES = "FREE_MINUTES";
export const FREE_MINUTES_SUMMARY = "FREE_MINUTES_SUMMARY";
export const BILLING_PAYMENT_DETAILS = "BILLING_PAYMENT_DETAILS";
export const BILLING_TRANSACTION_DETAILS = "BILLING_TRANSACTION_DETAILS";

export const MAP_FETCH_CARS = "MAP_FETCH_CARS";
export const MAP_CREATE_CAR_RESERVATION = "MAP_CREATE_CAR_RESERVATION ";
export const MAP_REMOVE_CAR_RESERVATION = "MAP_REMOVE_CAR_RESERVATION";

export const STATEMENTS_INFO = "STATEMENTS_INFO";

export const REGIONS_INFO = "REGIONS_INFO";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export const SET_ALERTS = "SET_ALERTS";
export const RESET_ALERTS = "RESET_ALERTS";
export const DISMISS_ALERT = "DISMISS_ALERT";

export const SET_USER_RESET_EMAIL = "SET_USER_RESET_EMAIL";
// Settings
export const SPINNER_ON = "SPINNER_ON";
export const SPINNER_OFF = "SPINNER_OFF";
export const SET_DROPDOWN_OPTIONS = "SET_DROPDOWN_OPTIONS";
export const SHOW_SUB_NAV = "SHOW_SUB_NAV";

// ROUTE Constants
export const ROUTE_HOME = "/";
export const ROUTE_FIND_A_CAR = "/findacar";
export const ROUTE_RESET_PASSWORD = "/reset/password";
export const ROUTE_RESET_PASSWORD_CODE = "/reset/password/code";
export const ROUTE_REGISTRATION = "/registration";
export const ROUTE_PRIVATE_REGISTRATION = "/private/reg";
export const ROUTE_PRIVATE_REGISTRATION_OTP = "/private/reg/otp";
export const ROUTE_PRIVATE_REGISTRATION_SUCCESS = "/private/reg/success";
export const ROUTE_CONTACT_US_BUSINESS = "/contact/business";
export const ROUTE_SIGNIN = "/signin";
export const ROUTE_ACCOUNT = "/account/dashboard";
export const ROUTE_ACCOUNT_DETAILS = `${ROUTE_ACCOUNT}/details`;
export const ROUTE_ACCOUNT_MINUTES = `${ROUTE_ACCOUNT}/minutes`;
export const ROUTE_ACCOUNT_BILLING = `${ROUTE_ACCOUNT}/billing`;
export const ROUTE_ACCOUNT_BILLING_DETAIL = `${ROUTE_ACCOUNT}/billing/:paymentId?`;
export const ROUTE_ACCOUNT_STATEMENTS = `${ROUTE_ACCOUNT}/statements`;
export const ROUTE_ACCOUNT_LOGIN_INFO = "/account/logininfo";
export const ROUTE_ACCOUNT_CHANGE_PASSWORD = "/account/change-password";
export const ROUTE_ACCOUNT_PERSONAL = "/account/user";
export const ROUTE_ACCOUNT_DRIVERS = "/account/drivers";
export const ROUTE_ACCOUNT_PAYMENT = "/account/payment";
export const ROUTE_ACCOUNT_PREFERENCE_CENTER = "/account/preference-center";

// API Constants
export const REACT_APP_PROPEL_SERVICE_URL = process.env.REACT_APP_PROPEL_SERVICE_URL;

export const ENDPOINTS = {
  //getRegions: `${REACT_APP_PROPEL_SERVICE_URL}/api/regions`,
  validatePromo: `${REACT_APP_PROPEL_SERVICE_URL}/api/validate/promo_code`,
  feeSummary: `${REACT_APP_PROPEL_SERVICE_URL}/api/reg/cost_breakdown`,
  register: `${REACT_APP_PROPEL_SERVICE_URL}/api/reg/customer`,
  registerEBikeOnly: `${REACT_APP_PROPEL_SERVICE_URL}/api/reg/ebike_customer`,
  registerUpdateEBikeOnly: `${REACT_APP_PROPEL_SERVICE_URL}/api/reg/update_ebike_customer`,
  registerBusinessAccount: `${REACT_APP_PROPEL_SERVICE_URL}/api/reg/b2b_evolve`,
  detectUserLocation: `${REACT_APP_PROPEL_SERVICE_URL}/api/detectUserLocation`,
  validateEmail: `${REACT_APP_PROPEL_SERVICE_URL}/api/validate/email`,
  validateDriverLicenseNumber: `${REACT_APP_PROPEL_SERVICE_URL}/api/validate/dl_number`,
  validateEmailDomain: `${REACT_APP_PROPEL_SERVICE_URL}/api/validate/email_domain`,
  validateExistingCustomer: `${REACT_APP_PROPEL_SERVICE_URL}/api/validate/existing_customer`,
  validateBusinessEmailOtp: `${REACT_APP_PROPEL_SERVICE_URL}/api/validate/business_email_otp`,
  resendBusinessEmailOtp: `${REACT_APP_PROPEL_SERVICE_URL}/api/resend/business_email_otp`,
  validatePhone: `${REACT_APP_PROPEL_SERVICE_URL}/api/validate/phone`,
  validateDriversLicense: `${REACT_APP_PROPEL_SERVICE_URL}/api/validate/dl`,
  validateDriversLicenseFront: `${REACT_APP_PROPEL_SERVICE_URL}/api/validate/dl`,
  validateDriversLicenseBack: `${REACT_APP_PROPEL_SERVICE_URL}/api/validate/dl`,
  validateSelfie: `${REACT_APP_PROPEL_SERVICE_URL}/api/validate/selfie`,
  validateBCAAMembership: `${REACT_APP_PROPEL_SERVICE_URL}/api/validate/member`,
  createAdyenSession: `${REACT_APP_PROPEL_SERVICE_URL}/api/adyen/session_create`,
  sendEmail: `${REACT_APP_PROPEL_SERVICE_URL}/api/contact_us/business`,
  validateB2BAccessCode: `${REACT_APP_PROPEL_SERVICE_URL}/api/validate/b2b_access_code`,
};

export const FORM = {
  registrationForm: "registrationForm",
  privateRegistrationForm: "privateRegistrationForm",
  verifyOtpForm: "verifyOtpForm",
  contactUsBusinessForm: "contactUsBusinessForm",

  // unused Form's, commenting out breaks unit test
  updatePreferenceCenterForm: "updatePreferenceCenterForm",
  updateBillingAddressForm: "updateBillingAddressForm",
  updateCreditCardForm: "updateCreditCardForm",
  updateEmailForm: "updateEmailForm",
  applyPromoCodeForm: "applyPromoCodeForm",
  ForgotPasswordEmailForm: "ForgotPasswordEmailForm",
  SearchBillingPaymentsForm: "searchBillingPaymentsForm"
};

export const LOGIN_BANNER_TYPE = {
  resetPasswordSuccess: "resetPasswordSuccess",
  changeEmailSuccess: "changeEmailSuccess"
};

export const ACCOUNT_STATUS = {
  SUSPENDEDOSB: "SUSPENDED-OSB",
  SUSPENDEDFLAG: "SUSPENDED-FLAG",
  UNSUSPENDED: "UNSUSPENDED"
};

export const PROMO_CODE_STATUS = {
  error: "error",
  primary: "primary"
};

export const DRIVER_LICENSE_STATUS = {
  error: "error",
  success: "success"
};

export const SELFIE_STATUS = {
  error: "error",
  success: "success"
};

export const REGISTRATION_STATUS = {
  error: "error",
  success: "success"
};

export const DRIVING_RECORD_STATUS = {
  PENDING: "PENDING",
  DECLINED: "DECLINED"
};

export const ALERT_LEVEL = {
  success: "success",
  warning: "warning",
  error: "error"
};

export const FREE_MIN_TYPE = {
  GOODWILL_THANK_YOU: "GOODWILL_THANK_YOU",
  SERVICE_RECOVERY: "SERVICE_RECOVERY",
  SERVICE_DISRUPTION: "SERVICE_DISRUPTION",
  PROMO_MINUTES_EXTENSION: "PROMO_MINUTES_EXTENSION",
  PROMO_MINUTES_EXCEPTION: "PROMO_MINUTES_EXCEPTION",
  REFERRED_TO: "REFERRED_TO",
  REFERRED_BY: "REFERRED_BY",
  OTHER: "OTHER"
};

export const IS_MULTI_REGION = process.env.REACT_APP_MULTI_REGION === "true";

//Registration Constants
export const PROGRESS_BAR_LENGTH = 5;
