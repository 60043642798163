/* eslint-disable import/no-named-as-default */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import get from "lodash/get";

import { Logo } from "../../elements";
import { ConnectedProgressbar, DependOn } from "../../components";
import { messages, constants, options } from "../../constants";
import * as selectors from "../../selectors";
import "./header.scss";
import { NavBar } from "../../containers";
import { checkIsAccountPath, checkIsRegPath } from "./headerHelper";

import { withRouter } from "react-router-dom";

class Header extends PureComponent {
  constructor(props) {
    super(props);
    const { intl, isEbikeOnly } = this.props;
    this.state = {
      progressBarItems: this.updateProgressBar({ intl, isEbikeOnly })
    };
  }
  componentDidUpdate(prevProps) {
    const { intl, isEbikeOnly } = this.props;
    if (isEbikeOnly !== prevProps.isEbikeOnly)
      this.setState({ progressBarItems: this.updateProgressBar({ intl, isEbikeOnly }) });
  }

  updateProgressBar({ intl, isEbikeOnly }) {
    return options
      .progress(isEbikeOnly)
      .map((x) => ({ ...x, label: intl.formatMessage(messages.title[x.label]) }));
  }

  render() {
    const { sub, intl, isLoggedIn, user, pathname } = this.props;
    const headerCSS = cn("header", sub ? "header--sub" : "");
    const name =
      get(user, "personalInfo.preferredName", "") || get(user, "personalInfo.firstName", "");
    const navList = [
      {
        title: intl.formatMessage(messages.title.AccountDetails),
        link: constants.ROUTE_ACCOUNT,
        type: "link",
        exact: true
      },
      {
        title: intl.formatMessage(messages.title.Billing),
        link: constants.ROUTE_ACCOUNT_BILLING,
        type: "link"
      },
      {
        title: intl.formatMessage(messages.title.MyMinutes),
        link: constants.ROUTE_ACCOUNT_MINUTES,
        type: "link"
      },
      {
        title: intl.formatMessage(messages.title.Statements),
        link: constants.ROUTE_ACCOUNT_STATEMENTS,
        type: "link"
      }
    ];

    return (
      <header className={headerCSS}>
        <section className="header__main">
          <Logo />
          <div className="header__tools">
            <ul className="header__tools-list" role="navigation">
              {isLoggedIn && (
                <li>{intl.formatMessage(messages.header.greeting, { name: name })}</li>
              )}
            </ul>
            <label htmlFor="nav-checkbox" role="button">
              {intl.formatMessage(messages.header.menu)}
              <i className="fa fa-bars" role="presentation" />
            </label>
          </div>
        </section>

        <DependOn active={checkIsRegPath(pathname)}>
          <section className="header__sub">
            <div className="header__sub-inner">
              <ConnectedProgressbar {...this.props} sub={this.state.progressBarItems} />
            </div>
          </section>
        </DependOn>

        <DependOn active={checkIsAccountPath(pathname)}>
          <NavBar navList={navList} currentLink={pathname} />
        </DependOn>
      </header>
    );
  }
}

Header.propTypes = {
  intl: PropTypes.object.isRequired,
  sub: PropTypes.array,
  story: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  user: PropTypes.object,
  isEbikeOnly: PropTypes.bool,
  pathname: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  sub: selectors.getPageSubNav(state),
  isLoggedIn: selectors.isLoggedIn(state),
  user: selectors.getUser(state),
  pathname: state.router.location.pathname,
  isEbikeOnly: selectors.getIsEbikeOnly(state)
});

const enhance = compose(
  connect(mapStateToProps),
  injectIntl,
  withRouter
);

export default enhance(Header);
