import React, { PureComponent } from "react";
import { Field, formValueSelector } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { Input, Grid, Col, Section } from "../../../../components";
import { Txt } from "../../../../elements";
import { messages } from "../../../../constants";
import { validationBuilder, validations } from "../..";

let rules = {};
class Credentials extends PureComponent {
  rulesEmail = [];
  rulesPassword = [];
  rulesConfirmEmail= [];

  constructor(props) {
    super(props);

    // for field level validations
    // TODO: find a better way to instantiate props when the component mounts
    Object.keys(validations).forEach(key => {
      rules[key] = spec => (value, allValues) => validationBuilder(props, validations[key], spec)(value, allValues);
    });

    const {intl} = props;
    this.rulesEmail = [
      rules.required(intl.formatMessage(messages.field.EmailAddress)),
      rules.email()
    ];

    this.rulesConfirmEmail = [
      rules.requiredConfirmEmail(),
      rules.email(),
      rules.emailsMatch()
    ];

    this.rulesPassword = [
      rules.required(intl.formatMessage(messages.field.password)),
      rules.shouldHaveSpecialChar(),
      rules.shouldHaveNumber(),
      rules.shouldHaveUpper(),
      rules.shouldHaveLower(),
      rules.shouldHave8Chars(),
      rules.shouldNotHaveSpaces()
    ];
  }

  // function to get the selected field value
  getValue = name => {
    const { state, form, section } = this.props;
    const selector = formValueSelector(form);
    return selector(state, `${section}.${name}`);
  };

  render() {
    const { intl } = this.props;
    return (
      <Section size="1-2" min="md">
        <Grid>
          <Col>
            <Field
              type="email"
              name="emailAddress"
              label={intl.formatMessage(messages.field.EmailAddress)}
              required={true}
              validate={this.rulesEmail}
              component={Input}
              props={{ maxLength: "254" }}
            />
            <Txt theme={["note"]}>
              <small>{intl.formatMessage(messages.field.emailNote)}</small>
            </Txt>
          </Col>
          <Col>
            <Field
              type="email"
              name="confirmEmailAddress"
              label={intl.formatMessage(messages.field.confirmEmailAddress)}
              required={true}
              validate={this.rulesConfirmEmail}
              component={Input}
              props={{ maxLength: "254" }}
              allowPaste={false}
            />
          </Col>
          <Col size="2-3" min="sm">
            <Field
              type="password"
              name="password"
              label={intl.formatMessage(messages.field.password)}
              component={Input}
              validate={this.rulesPassword}
              required={true}
              props={{ maxLength: "50" }}
            />
            <Txt theme={["note"]}>
              <small>{intl.formatMessage(messages.field.passwordNote)}</small>
            </Txt>
          </Col>
        </Grid>
      </Section>
    );
  }
}

Credentials.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired
};

export default injectIntl(Credentials);
