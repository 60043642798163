const evoOptions = [
  {
    name: "PersonalInfo",
    label: "ContactDetailsProgressTitle",
    showContinueButton: false,
    allowBack: false
  },
  {
    name: "LicenceDetails",
    label: "DriversLicenseProgressTitle",
    hasSubOptions: true,
    subOptions: ["AddDriversLicenseFront", "AddDriversLicenseBack", "DriversLicenseInfo"],
    showContinueButton: true,
    allowBack: true
  },
  {
    name: "IdentityVerificationSelfie",
    label: "IdentityVerificationProgressTitle",
    showContinueButton: true,
    allowBack: true
  },
  {
    name: "PromosAndSavings",
    label: "PromosAndSavingsProgressTitle",
    showContinueButton: true,
    allowBack: true
  },
  {
    name: "PaymentInfo",
    label: "PaymentProgressTitle",
    showContinueButton: false,
    allowBack: false
  },
  {
    name: "RequestDrivingRecord",
    label: "DrivingRecordProgressTitle",
    showContinueButton: false,
    allowBack: false
  }
];

const evolveOptions = [
  {
    name: "PersonalInfo",
    label: "ContactDetailsProgressTitle",
    showContinueButton: false,
    allowBack: false
  },
  {
    name: "PromosAndSavings",
    label: "PromosAndSavingsProgressTitle",
    showContinueButton: true,
    allowBack: false
  },
  {
    name: "PaymentInfo",
    label: "CreditCardInfoProgressTitle",
    showContinueButton: false,
    allowBack: false
  },
  {
    name: "EbikeOnlyConfirmation",
    label: "ConfirmationProgressTitle",
    showContinueButton: false,
    allowBack: false
  }
];
const fn = (isEbikeOnly) => (isEbikeOnly ? evolveOptions : evoOptions);

export default fn;
