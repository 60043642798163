import { defineMessages } from "react-intl";

const namespace = `member`;
const privateRegistration = defineMessages({
  isEvoMember: {
    id: `${namespace}.isEvoMember`,
    description: "Are you an Evo Member? radio Field",
    defaultMessage: "Are you an Evo Member?"
  },
  businessEmailAddress: {
    id: `${namespace}.businessEmailAddress`,
    description: "Business email address",
    defaultMessage: "Business email address"
  },
  personalEmailAddress: {
    id: `${namespace}.personalEmailAddress`,
    description: "personal email address",
    defaultMessage: "Personal email address"
  },
  accessCode: {
    id: `${namespace}.accessCode`,
    description: "access Code",
    defaultMessage: "Access code"
  },
  evoAccountEmailAddress: {
    id: `${namespace}.evoAccountEmailAddress`,
    description: "evo Account email address",
    defaultMessage: "Personal Evo account email address"
  },
  businessEmailNote: {
    id: `${namespace}.businessEmailNote`,
    description: "businessEmailNote",
    defaultMessage: "Enter your business email address, skip if you have a valid access code above."
  },
  accessCodeNote: {
    id: `${namespace}.accessCodeNote`,
    description: "accessCodeNote",
    defaultMessage: "Skip this step if you have a business email address"
  },
  personalEmailNote: {
    id: `${namespace}.personalEmailNote`,
    description: "personalEmailNote",
    defaultMessage: "The email address you'll use to register for Evo"
  },
  evoAccountEmailNote: {
    id: `${namespace}.evoAccountEmailNote`,
    description: "evoAccountEmailNote",
    defaultMessage: "Your existing personal Evo account email address"
  },
  NewAccountInstructions: {
    id: `${namespace}.NewAccountInstructions`,
    description: "NewAccountInstructions",
    defaultMessage:
      "{br}By clicking 'Continue', you will be prompted to complete the Evo registration by entering your personal details. Please ensure to enter the {sameaddress} when registering, and select {onlyebike}."
  },
  BBOEmailInstructions: {
    id: `${namespace}.BBOEmailInstructions`,
    description: "BBOEmailInstructions",
    defaultMessage:
      "The Evolve team is currently reviewing your account details. You'll receive a confirmation email within 24 hours and you can hop on an e-bike right away!"
  },
  BCOEmailInstructions: {
    id: `${namespace}.BCOEmailInstructions`,
    description: "BCOEmailInstructions",
    defaultMessage:
      "You're now officially an Evolve Member, which means you can book, hop on and hit the road with Evolve E-Bikes/E-Scooters using your Evo App.{br}{br}Keep an eye on your inbox for more info or visit {evolve}"
  },
  OTPEmailInstructions: {
    id: `${namespace}.OTPEmailInstructions`,
    description: "OTPEmailInstructions",
    defaultMessage: "Verification code"
  },
  resendOTP: {
    id: `${namespace}.resendOTP`,
    description: "resendOTP",
    defaultMessage: "Didn't receive your code? Click to resend"
  },
  instructionText: {
    id: `${namespace}.instructionText`,
    description: "instructionText",
    defaultMessage: "Enter an access code or your business email address to sign up"
  }
});

export default privateRegistration;
