import React, { PureComponent } from "react";

import { Field, formValueSelector } from "redux-form";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import { Input, Dropdown, Grid, Col, Section, DependOn, Datepicker } from "../../../../components";
import { messages } from "../../../../constants";

import { validationBuilder, validations } from "../..";
import { Txt } from "../../../../elements";

let rules = {};
class PersonalInfo extends PureComponent {
  rulePhone = [];

  constructor(props) {
    super(props);
    // for field level validations
    // TODO: find a better way to instantiate props when the component mounts
    Object.keys(validations).forEach((key) => {
      rules[key] = (spec) => (value) => validationBuilder(props, validations[key], spec)(value);
    });
    const { intl } = props;
    this.rulePreferredName = [rules.names(intl.formatMessage(messages.personal.preferredName))];
    this.ruleFirstName = [
      rules.required(intl.formatMessage(messages.personal.firstName)),
      rules.names(intl.formatMessage(messages.personal.firstName))
    ];
    this.ruleLastName = [
      rules.required(intl.formatMessage(messages.personal.lastName)),
      rules.names(intl.formatMessage(messages.personal.lastName))
    ];
    this.ruleMiddleName = [rules.names(intl.formatMessage(messages.personal.middleName))];

    this.rulePronounName = [
      rules.pronounNames(intl.formatMessage(messages.personal.pronounOtherCustom))
    ];
    this.ruleDob18 = [
      rules.isValidDate(intl.formatMessage(messages.error.invalidDate)),
      rules.required(intl.formatMessage(messages.personal.dob)),
      rules.isOverAge(18)
    ];
  }

  // function to get the selected field value
  getValue(name) {
    const { state, form, section } = this.props;
    const selector = formValueSelector(form);
    return selector(state, `${section}.${name}`);
  }

  render() {
    const { intl } = this.props;
    return (
      <Section size="1-2" min="md">
        <Grid>
          <Col size="2-3" min="sm">
            <Field
              type="text"
              name="firstName"
              label={intl.formatMessage(messages.personal.firstName)}
              required={true}
              component={Input}
              validate={this.ruleFirstName}
              props={{ maxLength: "50" }}
            />
          </Col>
          <Col size="2-3" min="sm">
            <Field
              type="text"
              name="preferredName"
              label={intl.formatMessage(messages.personal.preferredName)}
              component={Input}
              validate={this.rulePreferredName}
              props={{ maxLength: "50" }}
            />
          </Col>
          <Col size="2-3" min="sm">
            <Field
              type="text"
              name="middleName"
              label={intl.formatMessage(messages.personal.middleName)}
              component={Input}
              validate={this.ruleMiddleName}
              props={{ maxLength: "50" }}
            />
          </Col>
          <Col size="2-3" min="sm">
            <Field
              type="text"
              name="lastName"
              label={intl.formatMessage(messages.personal.lastName)}
              required={true}
              component={Input}
              validate={this.ruleLastName}
              props={{ maxLength: "50" }}
            />
          </Col>
          <Col size="2-3" min="sm">
            <Field
              name="pronoun"
              label={intl.formatMessage(messages.personal.pronoun)}
              required={false}
              options={[
                {
                  label: intl.formatMessage(messages.personal.pronounHeHim),
                  value: intl.formatMessage(messages.personal.pronounHeHim)
                },
                {
                  label: intl.formatMessage(messages.personal.pronounSheHer),
                  value: intl.formatMessage(messages.personal.pronounSheHer)
                },
                {
                  label: intl.formatMessage(messages.personal.pronounTheyThem),
                  value: intl.formatMessage(messages.personal.pronounTheyThem)
                },
                {
                  label: intl.formatMessage(messages.personal.pronounHeThey),
                  value: intl.formatMessage(messages.personal.pronounHeThey)
                },
                {
                  label: intl.formatMessage(messages.personal.pronounSheThey),
                  value: intl.formatMessage(messages.personal.pronounSheThey)
                },
                {
                  label: intl.formatMessage(messages.personal.pronounOther),
                  value: intl.formatMessage(messages.personal.pronounOther)
                },
                {
                  label: intl.formatMessage(messages.personal.pronounPreferNotToSay),
                  value: intl.formatMessage(messages.personal.pronounPreferNotToSay)
                }
              ]}
              component={Dropdown}
            />
            <Txt theme={["note"]}>
              <small>{intl.formatMessage(messages.field.pronounNote)}</small>
            </Txt>
          </Col>
          <Col size="2-3" min="sm">
            <DependOn active = {this.getValue('pronoun') === intl.formatMessage(messages.personal.pronounOther)}>

              <Field
                type="text"
                name="pronounCustom"
                label={intl.formatMessage(messages.personal.pronounOtherCustom)}
                validate={this.rulePronounName}
                component={Input}
                disabled={false}
                props={{ maxLength: "60" }}
              />
            </DependOn>
          </Col>
          <Col size="3-3" min="sm">
            <Field
              name="birthDate"
              label={intl.formatMessage(messages.personal.dob)}
              required={true}
              locale={intl.locale}
              validate={this.ruleDob18}
              component={Datepicker}
              initialMonth={moment().subtract(18, "years").toDate()}
              disabledDays={{
                after: moment().subtract(18, "years").toDate()
              }}
            />
          </Col>

        </Grid>
      </Section>
    );
  }
}

PersonalInfo.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired
};


export default injectIntl(PersonalInfo);
